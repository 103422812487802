/**
 * @ComponentFor LoginPage
 */
import React from 'react';
import { EpiProperty } from '@avensia/scope-episerver';
import LoginPageType from './LoginPage.type';
import { Main, Appearance } from 'Shared/PageLayout/index';
import { pixelsToUnit } from 'Shared/Style';

export default function LoginPage(props: LoginPageType) {
  return (
    <Main
      css={{
        paddingBottom: pixelsToUnit(100),
        maxWidth: pixelsToUnit(1000),
      }}
      appearance={Appearance.Narrow}
    >
      <EpiProperty for={props.mainContent} component="div" />
    </Main>
  );
}
